$primary: #0140BE;
$danger: #dd0000;
$success: #139108;
$warning: #EAC90E;
$secondary: #666A71;
$orange: #ff7a00;
//$info: #C5D8FF;
//$info: #C5D8FF;


.btn-orange {
  color: #000613;
  background-color: $orange;
  border-color: $orange;
}

.btn-orange {
  color: #000613;
  background-color: $orange;
  border-color: $orange;
}

.btn-orange:hover {
  color: #000613;
  background-color: $orange;
  border-color: $orange;
}

.btn-orange.focus, .btn-orange:focus {
  color: #000613;
  background-color: $orange;
  border-color: $orange;
  box-shadow: 0 0 0 .2rem rgba(255, 122, 0, 0.47)
}

.btn-orange.disabled, .btn-orange:disabled {
  color: #000613;
  background-color: $orange;
  border-color: $orange;
}

.btn-orange:not(:disabled):not(.disabled).active, .btn-orange:not(:disabled):not(.disabled):active, .show > .btn-orange.dropdown-toggle {
  color: #000613;
  background-color: $orange;
  border-color: $orange;
}

.btn-orange:not(:disabled):not(.disabled).active:focus, .btn-orange:not(:disabled):not(.disabled):active:focus, .show
.badge-orange {
  background-color: $orange;
  color: #000613;
  //margin: 1rem;
}


@import "../../../node_modules/bootstrap";