/* file-input::-webkit-file-upload-button {
    visibility: hidden;
} */

.fileInput {
    display: none
}

.label {
    text-align: center;
    width: 100%;
    cursor: pointer;
}

.labelContainer {
    font-size: 400%;
    font-weight: 400;
    border: 4px dashed rgba(0, 0, 0, 0.3);
    color: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.scerensaverContainer {
    font-size: 400%;
    font-weight: 400;
    border: 2px dashed rgba(0, 0, 0, 0.3);
    color: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    height: 512px ;
    width: 288px ;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 16/9;
}
.deleteImgBtn {
    display: relative;
    left: 0;
    top: 0;
}
.labelImg {
    border-radius: 8px;
    width:100%;
    height:100%;
    object-fit: cover;
}
.Skeleton {
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: #e7e7e7;
    width: 100%;
    height: 100%;
    border-radius: 8px;
}
.Skeleton::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
    animation: shimmer 5s infinite;
    content: '';
}
@keyframes shimmer {
    100% {
        transform: translateX(100%);
   }
}