@keyframes bgNonActive {
    from {background-color: red;}
    to {background-color: inherit;}
}
@keyframes bgActive {
    from {background-color: green;}
    to {background-color: inherit;}
}
.selectIngredientNonActive {
    /* background: white; */
    font-size: 50px;
    animation-name: bgNonActive;
    animation-duration: 1.5s;
}
.selectIngredientActive {
    /* background: white; */
    font-size: 50px;
    animation-name: bgActive;
    animation-duration: 1.5s;
}