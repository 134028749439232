    @keyframes spinner {
        0% {
        transform: rotate(0deg);
        }
        100% {
        transform: rotate(360deg);
        }
    }
  .loading {
	position: fixed;
	z-index: 2000;
	height: 100vh;
	width: 100%;
	overflow: show;
	margin: auto;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
	/* overflow: none; */
}

/* Transparent Overlay */
.loading:before {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: radial-gradient(rgba(100, 100, 100, 0.75), rgba(50, 50, 50, 0.75));
	background: -webkit-radial-gradient(rgba(100, 100, 100, 0.75), rgba(50, 50, 50, 0.75));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
	/* hide "loading..." text */
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}
.loader-text {
    content: "";
	position: absolute;
    text-align: center;
    justify-content: center;
    color: white;
    /* font-size: 30px; */
	display: flex;  
    width: 100%;
    justify-content: center;
    align-items: center;
	font-size: 25px;    
	margin-bottom: 5em;
    font-family: 'Lato', 'Arial', sans-serif;
}
.loader {
	content: "";
	position: absolute;
    text-align: center;
    justify-content: center;
    color: white;
    size: 10px;
	top: 50%;
	left: 50%;
	display: block;
	font-size: 10px;
	width: 1em;
	height: 1em;
	margin-top: -0.5em;
	-webkit-animation: spinner 150ms infinite linear;
	-moz-animation: spinner 150ms infinite linear;
	-ms-animation: spinner 150ms infinite linear;
	-o-animation: spinner 150ms infinite linear;
	animation: spinner 150ms infinite linear;
	border-radius: 0.5em;
	-webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
		rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
		rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
		rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
	box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
		rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
		rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
		rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}