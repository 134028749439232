::-webkit-scrollbar {
  width: 6px;
  height: 3px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(200,200,200,1);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#fff;
  -webkit-box-shadow: inset 0 0 6px rgba(90,90,90,0.7);
}